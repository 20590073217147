import { NgSwitch, NgSwitchCase } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { CrmTranslateService } from 'common-module/translate';
import { CrmTranslateMessage } from 'common-module/translate';
import {
  NzTypographyComponent,
  NzTypographyModule,
} from 'ng-zorro-antd/typography';

import { emptyValuePlaceholder } from '~/constants/common';

@Component({
  standalone: true,
  selector: 'app-text',
  template: `
    <ng-container [ngSwitch]="textType">
      <div
        nz-typography
        [nzType]="type"
        *ngSwitchCase="'paragraph'"
        [nzContent]="text"
        [nzCopyable]="copyable"
        [nzCopyText]="copyText"
        [nzCopyIcons]="['icons:copy-2-line', 'icons:check-line']"
      ></div>
      <span
        nz-typography
        [nzType]="type"
        *ngSwitchCase="'text'"
        [nzContent]="text"
        [nzCopyable]="copyable"
        [nzCopyText]="copyText"
        [nzCopyIcons]="['icons:copy-2-line', 'icons:check-line']"
      ></span>
    </ng-container>
  `,
  imports: [NgSwitch, NgSwitchCase, NzTypographyModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent implements OnInit {
  @Input() type?: NzTypographyComponent['nzType'];
  @Input() textType: 'text' | 'paragraph' = 'paragraph';
  @Input() weight: 'regular' | 'medium' | 'bold' = 'regular';
  @Input() size: 'x-small' | 'small' | 'medium' | 'large' = 'medium';
  @Input() margin: '0' | '1' | '2' | '3' = '0';
  @Input() colon = false;
  @Input() copyable = false;
  @Input() copyText?: string;
  @Input() message?: CrmTranslateMessage;
  @Input() text?: string;
  @Input() emptyValue: string = emptyValuePlaceholder;

  @HostBinding('class') protected hostClass!: string;

  private readonly translate = inject(CrmTranslateService);

  ngOnInit() {
    const baseClass = 'ehr-text';

    this.hostClass = [
      baseClass,
      `${baseClass}--${this.textType}`,
      `${baseClass}--weight-${this.weight}`,
      `${baseClass}--size-${this.size}`,
      `${baseClass}--margin-${this.margin}`,
    ].join(' ');

    this.text ??= [
      this.message ? this.translate.get(this.message) : this.emptyValue,
      this.colon ? ':' : '',
    ].join('');
  }
}
